/* SINGLE PRODUCT */
.single-product-page{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
  }

  .single-product {
	  display: flex;
  }

  .product-media{
    position: relative;
    width: 40%;
  }

  .product-media img{
	  width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

    .product-card-image-container {
      position: relative;
    }

    .product-card-image-container .out-of-stock{
      width: 100%;
      display: flex;
      z-index: 1;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(116, 52, 52, 0.5);
      color: var(--white);
      padding: 5px;
      border-radius: 5px;
    }

    .product-card-image-container .discount{
      width: 100%;
      display: flex;
      z-index: 1;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(58, 116, 52, 0.5);
      color: var(--white);
      padding: 5px;
      border-radius: 5px;
    }
  

  .product-media .image-gallery{
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto auto;
    gap: 5px;
  }

  .single-product .basic-input{
    background: var(--cream);
    border: none;
    border-radius: 5px;
  }

  .no-select {
	user-select: none; /* Prevents text selection */
  }
  
  .arrow {
	cursor: pointer; /* Makes it clear these are clickable */
  }

  .product-active-image{
    border-radius: 10px;
  }
  

  .product-image {
    border-radius: 5px;
  }
  .product-image.active {
	border: 2px solid #000; /* Style the active image differently */
  }

  .product-content{
	width: 60%;
	padding-left: 10%;
	box-sizing: border-box;
  }

  .product-content h1{
	font-size: 2.4rem;
	line-height: 2.8rem;
  }

  .product-content .product-description{
	font-size: 1.2rem;
	line-height: 1.6rem;
  }

  .product-content .product-price{
	font-size: 1.6rem;
	line-height: 1.8rem;
  }

  .discounted{
	text-decoration: line-through;
	margin-right: 10px;
  }

  .product-info p{
	margin-left: -5px;
	font-size: 1.1rem;
	padding-bottom: 10px;
  }

  .active-image{
    
    position: relative;
    
  }

  .active-image .arrow-container{
    position: absolute;
    color: var(--white);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 10px;
  }

  .active-image .arrow-container .arrow{
    background: rgba(28, 28, 28, 0.468);
    padding: 5px;
    
  }

  .active-image .arrow-container.left{
    left: 0;
  }

  .active-image .arrow-container.right{
    right: 0;
  }

  .active-image .arrow.left{
    left: 0;
  }

  .active-image .arrow.right{
    right: 0;
  }

@media screen and (max-width: 700px){
    .single-product{
        flex-direction: column-reverse;
    }

    .product-content{
      width: 100%;
      padding-left: 0;
      box-sizing: border-box;
      }
    
      .product-media{
        margin-top: 20px;
        position: relative;
        width: 100%;
      }
}
