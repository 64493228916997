/* HEADER */
.baneris{
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #383735;
    flex-direction: column;
    gap: 5px;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    transition: max-height 0.5s ease;
    overflow: hidden;
    max-height: 100px; 
	color: #fdf7e7;
}

.baneris p{
	text-align: center;
}

.baneris.light{
    background: #fdf7e7;
	color: #383735;
}

.baneris.hidden {
    max-height: 0;
	padding: 0;
}

.baneris.hidden p{
	display: none;
}

.header{
	position: fixed;
	top: 0;
	left: 0;
	background: #383735;
	width: 100%;
	min-height: var(--header-height);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	z-index: 999;
}

.header-container{
	width: 1100px;
}

.header .top-bar{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	padding: 10px 0;
}

.header .logo {
	display: flex;
	align-items: center;
	width: 200px;
}

.header .logo img {
	cursor: pointer;
	width: 100%;
}

.header .menu{
	display: flex;
	align-items: center;
	gap: 40px;
	justify-content: center;
}

.header .menu .header-menu-item{
	font-size: 1rem;
	color: var(--white);
	font-weight: 400;
	letter-spacing: 0.5px
}

.header .menu .header-menu-item:hover{
	transform: scale(1.02);
}

.header-cart{
	min-width: 200px;
	display: flex;
	justify-content: flex-end;
	padding-right: 20px;
}

.header .search{
	min-width: 200px;
}

.header-admin{
	height: var(--header-height);
	display: flex;
	align-items: center;
	gap: 40px;
}

.header-admin .header-menu-item{
	color: var(--white) !important;
}

.header-admin .header-menu-item:hover{
	transform: scale(1.05);
}

.header-cart .cart-icon{
	color: var(--white);
}

.cart-counter span{
	color: var(--white);
	font: inherit;
} 

.cart-counter{
	display: flex;
} 


.header.light{
	background: #fdf7e7;
	box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.header.light .header-menu-item{
	color: var(--black);
}
  
.header-cart.light,
.header-cart.light .cart-icon,
.header-cart.light .cart-counter span{
	color: var(--black);
}


.header .menu-item-with-submenu {
	position: relative;
  }
  
  .header .submenu {
	position: absolute;
	top: calc(100% + 15px); /* Position it below the parent menu item */
	left: 0;
	background: var(--black);
	display: flex;
	flex-direction: column;
	padding: 10px 15px;
	gap: 15px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.1);
	white-space: nowrap;
  }

  .header.light .submenu {
	background: var(--cream-light);
	
  }
  
  .header .submenu .header-menu-item {
	font-size: 0.9rem;
	color: var(--white);
	font-weight: 400;
	width: 100%;
  }

  .header.light .submenu .header-menu-item {
	color: var(--black);
  }
  
  .header .submenu .header-menu-item:hover {
	transform: scale(1.01);
	background: var(--dark-grey);
  }

@media screen and (max-width: 1100px){


	
	/* HEADER */
	.header{
		min-height: 80px;
	}

	.header-container{
		width: 100%;
	}

	.mobile-header{
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.header .menu-trigger{
		width: 25%;
		height: 80px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		padding: 0;
	}

	.header .logo{
		width: 50%;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.header .logo img{
		height: 60%;
		width: auto;
	}

	.header .header-cart{
		box-sizing: border-box;
		min-width: auto;
		width: 25%;
		height: 80px;
		padding: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.mobile-menu{
		position: fixed;
		top: 80px;
		left: 0;
		width: 100%;
		background: var(--black);
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		gap: 20px;
	}

	.mobile-menu.light{
		background: var(--cream-light);
	}

	.mobile-menu .header-menu-item{
		font-size: 1.1rem;
		color: var(--white);
		font-weight: 300;
		letter-spacing: 0.5px
	}

	.mobile-menu .menu-item-with-submenu {
		position: relative;
	  }
	
	  .mobile-menu .submenu {
		position: relative;
		background: var(--black);
		display: flex;
		flex-direction: column;
		gap: 15px;
	  }

	  .mobile-menu .submenu {
		box-shadow: none;
	  }

	
	  .mobile-menu .submenu .header-menu-item {
		font-size: 1.1rem;
		color: var(--white);
		font-weight: 200;
		letter-spacing: 0.5px;
	  }
	
	  .mobile-menu .submenu .header-menu-item:hover {
		background: var(--dark-grey);
	  }

	  .mobile-header .menu-trigger{
		color: var(--white);
	  }

	  .mobile-header.light .menu-trigger{
		color: var(--black);
	  }

	  .mobile-header.light .cart-icon,
	  .mobile-header.light .cart-counter span{
		color: var(--black);
	  }


	
}

