  .home-page{
    padding-bottom: 40px;
  }
  
  .hero-section {
    position: relative;
    width: 100%;
    padding-top: calc(var(--header-height));
    box-sizing: border-box;
  }

  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 0; /* Make sure it's behind the content but above the video */
}
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire area */
    z-index: -1; /* Place the video behind the content */
  }
  
  .hero-content {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    z-index: 1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
  }
  

.hero-content{
    width: 1100px;
}

.hero-content h1{
    font-size: 4rem;
    font-weight: 700;
    color: var(--cream-light);
    font-family: 'nora-spora', sans-serif;
    text-transform: capitalize;
}

.hero-content p{
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--cream-light);
}

@media screen and (max-width: 768px){
    .hero-content{
        width: 100%;
        padding: 0 20px;
        height: 40vh;
    }

    .hero-content h1{
        font-size: 3rem;
        padding-top: 20%;
    }

    .hero-content p{
        font-size: 1.1rem;
    }
}