/* QUESTIONS */

/* .add-question{
  min-height: 80vh;
} */

.ql-toolbar .ql-stroke {
	fill: none;
	stroke: var(--black);
  }
  
  .ql-toolbar .ql-fill {
	fill: var(--black);
	stroke: none;
  }
  
  .ql-toolbar .ql-picker {
	color: var(--black);
  }
  
  .my-quill .ql-container {
	height: 10rem;
	color: var(--black);
	font: inherit;
  }
  
  .my-quill p,
  .my-quill p em,
  .my-quill p span,
  .my-quill p a{
	color: var(--black) !important;
  }

.questions{
  user-select: none;
	display: flex;
	flex-direction: column;
	gap: 10px;
    box-sizing: border-box;
  }

  .question-page{
    display: flex;
  }

  .question-categories{
    width: 20%;
    display: flex;

    
    
  }

  .question-categories ul{
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  .question-categories .category{
    list-style: none;
    padding: 10px;
    border: 1px solid var(--darker-cream);
    border-radius: 15px;
    cursor: pointer;
  }

  .question-categories .category.selected{
    background-color: var(--darker-cream);
  }

  .question-area{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .question{
    display: flex;
    cursor: pointer;
    border: 1px solid var(--darker-cream);
    border-radius: 15px;
    justify-content: space-between;
    padding: 10px;
  }

  .question.active{
    background-color: var(--darker-cream);
  }

  .question h5{
    font-size: 1.1rem;
  }

  .question-controls{
	display: flex;
  }

  .question h4{
	font-size: 1.2rem;
  }

  .answer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  @media screen and (max-width: 1100px){
    .question-categories{
      display: none;
    }

    .question-area{
      width: 100%;
    }
  }