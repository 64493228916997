.about-us{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.about-us-media{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-media img{
    width: 80%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 10px;
}

.about-us-content{
    width: 100%;
}

.about-us-content p{
    font-size: 1.1rem;
    line-height: 1.3;
    text-align: justify;
}

@media screen and (max-width: 700px){
    .about-us-media img{
        margin-top: 20px;
        width: 100%;
    }
    .about-us-content{
        width: 100%;
    }
} 