.admin-instrukcija-teksts{
	border: 1px solid #ccc;
}

.admin-instrukcija-teksts:hover{
	background: #dad7ce;
	cursor: pointer;
}

.admin-instrukcija-teksts.active{
	background: #dad7ce;
}

.admin-instrukcija-teksts p{
	padding: 20px;
}

.custom-invoice form{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.custom-invoice form div{
	width: 50%;
	display: flex;
	flex-direction: column;
}

.custom-invoice form div input,
.custom-invoice form div select{
	height: 3rem;
}

.custom-invoice .product-picker{
	display: flex;
	flex-direction: column;
}

.custom-invoice .product-choice{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.custom-invoice .product-choice.discount input{
	height: 1.5rem;
}

.custom-invoice button{
	width: 200px;
	height: 40px;
	margin-bottom: 20px;
}


/* ADMIN */
.admin{
	min-height: 80vh;
}

.processing{
	height: 100vh;
}

.variations{
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 70%;
}

.variation{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	border: 1px solid #ccc;
}

.product-stock-management{
	display: flex;
	gap: 20px;
	align-items: center;
}


.edit-coupons{
	display: flex;
	flex-direction: column;
}

.add-coupon{
	display: flex;
	flex-direction: column;
}

.add-coupon .couponForm{
	display: flex;
	flex-direction: column;
	width: 50%;
	gap: 5px;
}

.add-coupon .couponForm input{
	height: 3rem;
	padding: 0 10px;
	font: inherit;
}

.add-coupon form input:focus-visible{
	outline: none;
}

.coupon-code{
	text-align: left;
	padding: 0 10px;
	height: 4rem;
}

.coupon-table tr:nth-child(odd) {
	background-color: #e5e5e5;
}

.coupon-table button {
}

.coupon-table tr:nth-child(odd):hover {
	background-color: #c5c5c5;
}

.coupon-table tr:nth-child(even) {
	background-color: #d5d5d5;
}

.coupon-table tr:nth-child(even):hover {
	background-color: #c5c5c5;
}

.edit-order{
	display: flex;
	flex-direction: column;
}

.login-page{
	display: flex;
	flex-direction: column;
}

.login-form{
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.login-form input{
	padding: 0 10px;
}

.login-form input,
.login-form button{
	height: 3rem;
	width: 25%;
	box-sizing: border-box;
	font: inherit;
}

.login-form input,
.login-form button:focus-visible{
	outline: none;
}

.admin{
	display: flex;
	flex-direction: column;
}

.add-question{
	display: flex;
	flex-direction: column;
}

.add-question form{
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 50%;
}

.add-question form input{
	height: 3rem;
	padding: 0 10px;
}

.add-question form textarea{
	height: 10rem;
	padding: 10px;
	resize: none;
}

.add-question form input,
.add-question form textarea:focus-visible{
	outline: none;
}

.admin-product-control-panel input{
	padding: 0 10px;
}

.admin-product-control-panel input:focus-visible{
	outline: none;
}

.admin-edit-products-table{
	width: 100%;
}

.admin-edit-products-table tr:nth-child(odd) {
	background-color: #e5e5e5;
}

.admin-edit-products-table tr:nth-child(odd):hover {
	background-color: #c5c5c5;
}

.admin-edit-products-table tr:nth-child(even) {
	background-color: #d5d5d5;
}

.admin-edit-products-table tr:nth-child(even):hover {
	background-color: #c5c5c5;
}



.admin-edit-products-table td,
.admin-edit-products-table th{
	box-sizing: border-box;
}

.admin-edit-products-table td img{
	width: 80px;
	aspect-ratio: 1/1;
	object-fit: cover;
}

.admin-edit-products-table tr{
	height: 100px;
	cursor: pointer;
}

.admin-edit-products-table td,
.admin-edit-products-table th{
	padding: 0 10px;
}

.admin-edit-products-table td.image{
	width: 100px;
}

.admin-edit-products-table td.price{
	width: 150px;
}

.admin-edit-products-table td.quantity{
	width: 150px;
}

.admin-edit-products-table td.order-title{
	text-align: center;
	width: 200px;
}

.admin-edit-products-table td.order-date{
	text-align: center;
	width: 150px;
}

.admin-edit-products-table td.order-price{
	text-align: center;
	width: 150px;
}

.admin-edit-products-table td.order-items{
	text-align: center;
}

.admin-edit-products-table td.order-status{
	text-align: center;
	width: 150px;
}

.admin-product-gallery{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;
}

.admin-product-gallery-image{
	position: relative;
}

.admin-product-gallery-image .admin-remove-image{
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}

.admin-product-gallery-image .admin-remove-image:hover{
	color: var(--red);
}

.admin-product-gallery-image img{
	width: 100%;
}

.admin-edit-products-table td.total{
	width: 150px;
}

.admin-edit-products-table td.remove{
	width: 100px;
	text-align: center;
}

.admin-edit-products-remove-button{
	color: var(--red);
}

.admin-edit-products-remove-button:hover{
	cursor: pointer;
	transform: scale(1.1);
}

.admin-dashboard{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.admin-dashboard h1{
	text-align: left;
}

.add-product-page{
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.add-product{
	display: flex;
	justify-content: center;
	align-items: stretch;
	box-sizing: border-box;
}

.add-product-form{
	display: flex;
	flex-direction: column;
	width: 50%;
}

.edit-coupons{
	min-height: 80vh;
}

.add-coupon{
	min-height: 80vh;
}

.add-product form{
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
}

.add-product-card-container{
	width: 50%;
	display: flex;
	/* justify-content: center;
	align-items: flex-start; */
	align-items: center;
	flex-direction: column;
}

.add-product-card{
	margin-top: 10%;
	width: 50%;
}

.add-product form input,
.add-product form textarea{
	min-height: 3rem;
	padding: 0 10px;
	resize: none;
	box-sizing: border-box;
	font: inherit;
	border-radius: 3px;
	border: 1px solid #ccc;
}

.added-image-gallery{
	padding-top: 40px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	width: 80%;
	gap: 10px;
}

.added-gallery-image{
	position: relative;

}

.added-gallery-image .admin-remove-image{
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	color: var(--red);
}

.added-gallery-image .admin-remove-image:hover{
	color: red;
}

.added-image-gallery img{
	width: 100%;
	aspect-ratio: 1/1;
	object-fit: cover;
}

.product-input-container{
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.form-group{
	display: flex;
}

.add-product form .form-group{
	display: flex;
	gap: 5px;
}

.add-product form .form-group.double .product-input-container{
	width: 50%;
}

.add-product form .form-group.tripple .product-input-container{
	width: 33.3%;
}

.add-product form input,
.add-product form textarea:focus-visible{
	outline: none;
}

.add-product form textarea{
	padding: 10px;
}

.add-product form textarea.desc{
	height: 10rem;
}

.admin-order-details{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.payment-client-info{
	display: flex;
	gap: 20px;
	min-height: 20vh;
}

.order-box{
	width: 50%;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border: 1px solid #ccc;
	gap: 10px;
}

.order-box h2{
	font-weight: 500;
}

.payment-info{
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
}

.order-product-details{
	display: flex;
	gap: 10px;
}


/* ADMIN ORDERS */
.admin-edit-orders-table{
	width: 100%;
}

.admin-edit-orders-table tr:nth-child(odd) {
	background-color: #e5e5e5;
}

.admin-edit-orders-table tr:nth-child(odd):hover {
	background-color: #c5c5c5;
}

.admin-edit-orders-table tr:nth-child(even) {
	background-color: #d5d5d5;
}

.admin-edit-orders-table tr:nth-child(even):hover {
	background-color: #c5c5c5;
}

.admin-edit-orders-table th,
.admin-edit-orders-table td{
	text-align: left;
	padding: 10px;
}

.admin-edit-orders-table th.checkbox,
.admin-edit-orders-table td.checkbox{
	text-align: center;
	width: 5%;
}

.admin-edit-orders-table td.title{
	width: 25%;
	cursor: pointer;
}

.admin-edit-orders-table td.items{
	width: 25%;
}

.admin-edit-orders-table td.date{
	width: 15%;
}

.admin-edit-orders-table td.status{
	width: 15%;
}

.admin-edit-orders-table td.price{
	width: 15%;
}

.admin-edit-orders-table ul{
	margin-left: 20px;
	list-style: disc;
}

.admin-edit-orders-table ul li{
	padding: 5px 0;
}
/* END ADMIN ORDERS */

/* ADMIN ORDER INFO */
.order-info{
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.order-info ul{
	list-style: disc;
	margin-left: 20px;
}

.order-info li{
	padding: 5px 0;
}
/* END ADMIN ORDER INFO */

.red-text{
	font-weight: bold;
	color: var(--red);
}

/* ADMIN COUPONS */
.product-checkbox input[type=checkbox]{
	height: 2rem;
}

.product-checkbox{
	display: flex;
	align-items: center;
}

.product-checkbox label{
	margin-left: 10px;
}

.order-controls .left,
.order-controls .right{
	width: 50%;
}

.admin-order-summary{
	display: flex;
	flex-direction: column;
	gap: 10px;
	border: 1px solid #c5c5c5;
	padding: 10px;
}