.noteikumi ol {
    counter-reset: item;
    padding: 0;
    margin: 0;
    font-weight: 500;
    margin-left: 15px;
    text-transform: uppercase;
    /* Specific styles for ordered lists */
}

.noteikumi ol p {
    padding-top: 10px;
    line-height: 1.4;
    font-weight: 400;
    text-transform: none;
    /* Specific styles for paragraphs inside ordered lists */
}

.noteikumi ol ul {
    text-transform: none;
    /* Specific styles for unordered lists inside ordered lists */
}

.noteikumi ol ol {
    padding-left: 20px;
    line-height: 1.5;
    font-weight: 400;
    text-transform: none;
    /* Specific styles for nested ordered lists */
}

.noteikumi ol li,
.noteikumi ul li {
    padding: 10px 0 0 0;
    margin: 0;
}

.noteikumi{
    padding-bottom: 20px;
}