.contacts{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 5%;
    box-sizing: border-box;
    margin-bottom: -140px;
}

.contacts-container{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-top: calc(var(--header-height) + 80px);
    flex-direction: column;
    width: 1100px;
}

.contact-card{
    background: var(--cream-light);
    width: 80%;
    min-height: 300px;
    display: flex;
    align-items: center;
    background-size: cover;
    color: var(--black);
    padding: 40px;
    box-sizing: border-box;
    border-radius: 15px;
    flex-direction: column;
}

.contacts form{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
    box-sizing: border-box;
    gap: 5px;
}

.contacts form input,
.contacts form textarea{
    background: var(--cream);
    border-radius: 2px;
    border: none;
}

.contacts form .form-group input{
    width: 50%;
}

.contacts form .form-group {
    gap: 5px;
}

.contacts textarea{
    padding: 10px;
    height: 7rem;
    resize: none;
}

.contacts p{
    padding-top: 20px;
    width: 80%;
}

@media screen and (max-width: 768px){
    .contacts form{
        width: 100%;
    }

    .contacts p{
        width: 100%;
    }

    .contacts form .form-group input{
        width: 100%;
    }

    .contacts form .form-group{
        flex-direction: column;
    }
}