.instrukcija-page p{
    line-height: 1.5;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
}

.instrukcija-page h3{
    font-family: 'nora-spora', sans-serif;
    font-weight: 500;
}


.card-container{
    display: flex;
   justify-content: space-between;
}

.card-container.tripple .card{
    width: 30%;
    flex-direction: column;
    /* border: 1px solid var(--gray); */
    background: var(--cream);
    border-radius: 12px;
    overflow: hidden;
}   

.card-container.tripple .card .card-text{
    padding: 20px;
}

.card-container.tripple .card .card-text h4{
    font-size: 1.4rem;
    font-weight: 500;
}

.card-container.tripple .card .card-text p{
    padding-top: 10px;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: justify;
}

.new-option {
    width: 70%;
}

.new-option .images {
    display: flex;
    gap: 10px;
    color: var(--cream-light);
}

.new-option .bloka-image,
.new-option .bloka-image img {
    position: relative;
    border-radius: 10px;
}

.new-option .bloka-image h2 {
    position: absolute;
    top: 10px;
    left: 10px; /* Add this line to position the text in the top-left corner */
    z-index: 2;
    margin: 0; /* Ensure there's no default margin */
    padding: 5px; /* Optional: Add some padding for better readability */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a background for better contrast */
    color: white; /* Optional: Ensure the text color is readable */
}



.instrukcijas-teksti{
    width: 70%;
}

.instrukcijas-teksti p{
    line-height: 1.5;
}

.instrukcijas-teksti.justified p{
    text-align: justify;
}

.instrukcijas-teksts{
    display: flex;
    flex-direction: column;
}

.instrukcijas-teksts .instrukcija-content{
    display: flex;
}

.instrukcijas-teksts .instrukcija-vizualis img{
    width: 90%;
    border-radius: 10px;
}


.instrukcija-content-text ul{
    padding-left: 20px;
    font-size: 1.2rem;
    list-style:disc;
}

.instrukcija-content-text li{
    padding-top: 10px;
}

.video-thumbnail{
    width: 20%;
    border-radius: 10px;
    cursor: pointer;
}

.video-thumbnail:hover{
    opacity: 0.8;
}



/* 
.instrukcijas-teksts .instrukcija-content{
    display: flex;
    width: 50%;
}

.instrukcijas-teksts .instrukcija-content p{
    font-size: 1.2rem;
}

.instrukcijas-teksts .instrukcija-vizualis{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.instrukcijas-teksts .instrukcija-vizualis img{
    width: 80%;
    border-radius: 10px;
}

.instruction-card ul{
    padding-left: 20px;
    font-size: 1.2rem;
    list-style:disc;
}

.instrukcija-page li{
    font-size: 1.2rem;
    padding-top: 10px;
}

.instrukcija-page p{
    text-align: justify;         
} */

@media screen and (max-width: 768px) {
    .new-option {
        width: 100%;
    }

    .video-thumbnail{
        width: 100%;
    }
    
    .instrukcijas-teksti{
        width: 100%;
    }

    .new-option .images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        color: var(--cream-light);
    }
    
    .new-option .bloka-image,
    .new-option .bloka-image img {
        position: relative;
        border-radius: 10px;
    }
    
}