/* PRODUCTS ARCHIVE */
.products-archive {
	display: flex;
	flex-direction: column;
	/* min-height: calc(100vh - var(--header-height)); */
    box-sizing: border-box;
}

.product-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr); /* Keeping the number of columns to 4 */
	gap: 20px;
}

.product-grid-card {
	background: #f9f4e9;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.product-grid-card .product-title {
	font-size: 1.3rem;
	line-height: 1.4rem;
	margin: 5px 0;
	text-align: left;
}

.product-price{
	font-size: 1.2rem;
}

.product-grid-card h3{
	font-size: 1rem;
}

.product-grid-card .product-card-image-container{
	width: 100%;
	overflow: hidden;
	border-radius: 10px;
}

.product-grid-card img.product-image {
	width: 100%;
	border-radius: 10px;
	aspect-ratio: 1/1;
	object-fit: cover;
	transition: transform 0.2s ease-in-out; /* Add smooth transition */
  }
  
  .product-grid-card img.product-image:hover {
	transform: scale(1.05); /* Slightly zoom in */
  }

.product-grid-card {
	background: #f9f4e9;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.product-grid-card .product-category {
	font-size: 0.8rem;
	color: #555;
	text-transform: uppercase;
	margin: 5px 0;
}

.product-grid-card .product-description {
	font-size: 1rem;
	line-height: 1.4rem;
	color: #555;
	margin-bottom: 20px;
	text-align: left;
	max-height: 4.2rem;
	overflow: hidden;
}

.product-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: auto; /* Ensures actions are at the bottom */
}

.add-to-cart-button {
	background: none;
	border: none;
	cursor: pointer;
	color: var(--dark-blue);
}

.add-to-cart-button:hover {
	color: var(--lighter-blue);
}

.add-to-cart-button.out-of-stock:hover {
	cursor: not-allowed;
	color: var(--dark-blue);
}

@media (max-width: 600px) {

	/* .product-grid {
		grid-template-columns: repeat(2,1fr); 
		gap: 10px;
	}

	.product-grid-card {
		padding: 10px;
	} */

	.product-grid-card .product-title {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	.product-grid-card .product-description {
		font-size: 0.9rem;
		line-height: 1.2rem;
		margin-bottom: 10px;
	}

	.product-actions {
		margin-top: 10px;
	}

	.add-to-cart-button {
		font-size: 0.8rem;
	}
}
