@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'nora-spora';
    src: url('../fonts/AGaramondPro-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'nora-spora';
    src: url('../fonts/AGaramondPro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

.pointer{
	cursor: pointer;
}

.toast-position {
	top: calc(10px + var(--header-height));
}

.blue-link{
	color: var(--indigo);
}

.cookies{
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--cream);
	z-index: 999;
	padding: 20px 0;
}

.cookies-container{
	width: 60%;
}


:root {
	--red: #980000;
	--salmon: #E18481;
	--dark-blue: #202533;
	--lighter-blue: #4f5974;
	--darker-cream: #d7cdc6;
	--cream: #e6ddd7;
	--darker-turquoise: #B3C2BF;
	--turquoise: #C1D0CD;
	--gray: #BAA7A5;
	--error-light: #E2BAB5;
	--error: #980000;
	--success-white: #DBEFE7;
	--darker-green: #077B50;

	--header-height: 100px;

	--card-background: #BAA7A5;

	--jungle-green: #49A078;
	--brown-cream: #e7d3bd;
	--cream-light: #FDF7E7;
	--dark-brown: #362715;

	--light-gray: #e4e4e4;
	--black: #383735;
	--light-black: #6d6969;
	--white: #F7F7F7;
	--blush: #DE6C83;
	--green: #558564;
	--indigo: #9D8DF1;
}

html {
	font-size: 14px;
}

body {
	background-color: var(--cream-light);
	margin: 0;
	padding: 0;
	font-family: "Raleway", sans-serif;
}

img{
	width: 100%;
}

.container {
	width: 1100px;
	margin: 0 auto;
	box-sizing: border-box;
	padding-top: calc(var(--header-height) + 40px + 60px);
	min-height: calc(100vh - var(--header-height));
}

.home-page,
.contacts{
	margin-bottom: calc(-100vh + var(--header-height));
}

.container-no-padding {
	width: 1100px;
	margin: 0 auto;
	box-sizing: border-box;
	padding-top: calc(var(--header-height) + 40px);
	padding: 0;
}

.section {
	padding: 100px 0;
}

.flex{
	display: flex;
}

.flex.column{
	flex-direction: column;
}

.flex.row{
	flex-direction: row;
	align-items: center;
}

.flex.space-between{
	justify-content: space-between;
}

.flex.flex-start{
	justify-content: flex-start;
}

.width-60{
	width: 60%;
}

.basic-input{
	height: 3rem;
	box-sizing: border-box;
	font: inherit;
	padding: 0 10px;
	border: none;
	background: var(--cream);
}

.basic-input:focus-visible{
	outline: none;
}

.flex.center{
	display: flex;
	justify-content: center;
	align-items: center;
}

.gap-10{
	gap: 10px;
}

.full-width{
	width: 100%;
}

.half-width{
	width: 50%;
}

.light{
	font-weight: 400;
}

.italic{
	font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a {
	color: var(--dark-blue);
	text-decoration: none;
}

a:hover {
	cursor: pointer;
}

a.dark {
	color: var(--purple);
}

a:hover {
	color: var(--lighter-blue);
	cursor: pointer;
}

.page-title{
	font-family: 'nora-spora', sans-serif;
	text-transform: uppercase;
	font-size: 3rem;
	line-height: 4rem;
	font-weight: 400;
}

h1 {
	font-family: 'nora-spora', sans-serif;
	text-transform: uppercase;
	font-size: 3.2rem;
	line-height: 4rem;
	font-weight: 400;
}

h2 {
	font-family: 'nora-spora', sans-serif;
	font-size: 2.8rem;
	line-height: 2.8rem;
	font-weight: 700;
}

h3 {
	font-size: 2.2rem;
	line-height: 2.2rem;
	font-weight: 700;
}

h4 {
	font-size: 1.8rem;
	line-height: 1.8rem;
	font-weight: 700;
}

h5 {
	font-size: 1.3rem;
	line-height: 1.3rem;
	font-weight: 700;
}

p.s {
	font-size: 0.8rem;
	line-height: 0.96rem;
}

p.r {
	font-size: 1rem;
	line-height: 1.2rem;
}

.small{
	font-size: 0.6rem;
	line-height: 0.96rem;
}

p.l {
	font-size: 1.2rem;
	line-height: 1.44rem;
}

p.xl {
	font-size: 1.4rem;
	line-height: 1.68rem;
}

.padding-5-bottom {
	padding-bottom: 5px !important;
}

.padding-5-top {
	padding-top: 5px !important;
}

.padding-10-bottom {
	padding-bottom: 10px !important;
}

.padding-10-top {
	padding-top: 10px !important;
}

.padding-10-right {
	padding-right: 10px !important;
}

.padding-10-left {
	padding-left: 10px !important;
}

.padding-20-bottom {
	padding-bottom: 20px !important;
}

.padding-20-top {
	padding-top: 20px !important;
}

.padding-20-left {
	padding-left: 20px !important;
}

.padding-20-right {
	padding-right: 20px !important;
}

.padding-30-bottom {
	padding-bottom: 30px !important;
}

.padding-30-top {
	padding-top: 30px !important;
}

.padding-40-bottom {
	padding-bottom: 40px !important;
}

.padding-40-top {
	padding-top: 40px !important;
}

.primary-button{
	background: var(--cream);
	color: var(--black);
	border: none;
	height: 3rem;
	line-height: 0;
	border-radius: 3px;
	cursor: pointer;
	transition: background-color 0.3s;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font: inherit;
}

.primary-button.dark{
	background: var(--light-black);
	color: var(--cream-light);
}


.primary-button:hover{
	transform: scale(1.05);
}

.button-add-to-cart {
	background-color: var(--white);
	padding: 0 20px;
	color: var(--black);
	font: inherit;
	border: 1px solid var(--black);
	line-height: 0;
	border-radius: 3px;
	cursor: pointer;
	height: 3rem;
  }
  
  .button-add-to-cart:hover {
	transform: scale(1.05);
  }

  .add-to-cart-button.out-of-stock:hover {
	cursor: not-allowed;
	}


.button-secondary {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--cream);
	border: none;
	height: 65px;
	font: inherit;
	padding: 0 20px;
}

.button-secondary:hover {
	background: var(--darker-sage);
	cursor: pointer;
}

.button-social {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--turquoise);
	border: none;
	height: 60px;
	font: inherit;
	padding: 0 20px;
	min-width: 100%;
}

.button-social:hover {
	background: var(--darker-turquoise);
	cursor: pointer;
}

.bold {
	font-weight: 700;
}

.two-column-flex{
	display: flex;
	min-height: 50vh;
}

.two-column-flex > div{
	flex: 1;
	width: 50%;
}

.dropdown-menu {
	position: absolute;
	margin-top: 3rem;
	width: 100%; /* Adjust width as needed */
	border-top: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	max-height: 200px;
	overflow-y: auto;
	background: var(--white);
	z-index: 10; /* Ensure it's above other elements */
  }
  
  .dropdown-item {
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	border-top: none;
	padding: 20px;
  }
  
  .select-dropdown {
	position: relative; /* Container needs to be relative */
  }
  
  .select-dropdown input {
	height: 3rem;
	box-sizing: border-box;
	font: inherit;
	padding: 0 10px;
  }
  
  .select-dropdown input:focus-visible,
  .select-dropdown select:focus-visible {
	outline: none;
  }

  .confirmation-screen{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
  }

  .confirmation-box{
	background: var(--white);
	padding: 20px;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	align-items: center;
  }

  .confirmation-box h2{
	font-size: 2rem;
	line-height: 2.4rem;
	font-weight: 500;
  }

  .confirmation-buttons{
	margin-top: 20px;
	display: flex;
	gap: 10px;
  }


  

/* .two-column-flex .left{
	background: red;
}

.two-column-flex .right{
	background: blue;
} */


/* SPINNER */
.loadingSpinnerContainer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5000;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .loadingSpinner {
	width: 64px;
	height: 64px;
	border: 8px solid;
	border-color: #000 transparent #555 transparent;
	border-radius: 50%;
	animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

@media screen and (max-width: 1130px) {
	.container {
		width: 100%;
		padding: 0 5%;
		padding-top: calc(var(--header-height) + 80px);
	}

	.hero-content{
		width: 100% !important;
		padding: 0 5%;
	}

	.container-no-padding {
		width: 100%;
		padding: 0 5%;
	}

	.product-grid{
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	


	.section {
		padding: 50px 0;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.8rem;
		line-height: 2rem;
	}

	h3 {
		font-size: 1.6rem;
		line-height: 1.8rem;
	}

	h4 {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	h5 {
		font-size: 1.2rem;
		line-height: 1.4rem;
	}

	p.s {
		font-size: 0.7rem;
		line-height: 0.84rem;
	}

	p.r {
		font-size: 0.9rem;
		line-height: 1.08rem;
	}

	p.l {
		font-size: 1rem;
		line-height: 1.2rem;
	}

	p.xl {
		font-size: 1.2rem;
		line-height: 1.44rem;
	}

	.two-column-flex{
		flex-direction: column-reverse;
	}

	.two-column-flex > div{
		width: 100%;
	}
	
}


/* MOBILE */
@media (max-width: 600px) {
	.container {
		width: 100%;
		padding: 10px 20px;
		padding-top: calc(var(--header-height) + 80px);
	}

	.container-no-padding {
		width: 100%;
		padding: 10px 20px;

	}

	.section {
		padding: 50px 0;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.8rem;
		line-height: 2rem;
	}

	h3 {
		font-size: 1.6rem;
		line-height: 1.8rem;
	}

	h4 {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	h5 {
		font-size: 1.2rem;
		line-height: 1.4rem;
	}

	p.s {
		font-size: 0.7rem;
		line-height: 0.84rem;
	}

	p.r {
		font-size: 0.9rem;
		line-height: 1.08rem;
	}

	p.l {
		font-size: 1rem;
		line-height: 1.2rem;
	}

	p.xl {
		font-size: 1.2rem;
		line-height: 1.44rem;
	}

	.two-column-flex{
		flex-direction: column-reverse;
	}

	.two-column-flex > div{
		width: 100%;
	}
}