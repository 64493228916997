/* FOOTER */
.footer-container {
	width: 1100px;
	padding: 20px;
	display: flex;
	align-items: center;
	padding: 20px;
}

.footer{
	width: 100%;
	display: flex;
	background: var(--cream);
	color: var(--black);
	justify-content: center;
	align-items: center;
}

.footer ul{
	width: 33%;
	display: flex;
}

.footer .left{
	flex-direction: column;
	gap: 10px;
}

.footer .center{
	gap: 10px;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
}

.footer .right{
	gap: 10px;
	justify-content: flex-end;
}

.footer .mobile{
	display: none;
}

@media screen and (max-width: 768px){
	.footer{
		flex-direction: column;
		padding: 20px;
		box-sizing: border-box;
	}

	.footer-container{
		width: 100%;
	}

	.footer-container .left{
		width: 50%;
	}

	.footer-container .center{
		display: none;
	}

	.footer-container .right{
		width: 50%;
	}

	.footer .mobile{
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-top: 20px;
	}
	
}

