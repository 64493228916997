/* CHECKOUT */
.checkout-page{
	display: flex;
	/* min-height: calc(100vh - var(--header-height)); */
    box-sizing: border-box;
}
.selectButton{
	background-color: #f8f9fa;
	border: none;
	border-radius: 2px;
	color: var(--black);
	cursor: pointer;
	padding: 10px 20px;
	border: 1px solid #ccc;
}

.selectButton.selected{
	background-color: #f3ffe6;
	color: var(--black);
}

.checkout{
	display: flex;
	flex-direction: column;
	width: 40%;
	padding-right: 10%;
}


.checkout-form{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.checkout-form h4{
	font-weight: 500;
	font-size: 1.4rem;
}

.checkout-form .split-input{
	display: flex;
	gap: 10px;
}

.checkout-form .split-input input{
	width: 50%;
	box-sizing: border-box;
}

.checkout-form input{
	height: 3rem;
	padding: 0 10px;
	resize: none;
	box-sizing: border-box;
	font: inherit;
	border-radius: 3px;
	border: none;
	background: var(--cream);
}

.order-summary{
	width: 50%;
}

.order-summary ul{
	list-style: disc;
	margin-left: 20px;
}

.order-summary ul li{
	padding: 5px 0;
}

.order-summary h4{
	font-weight: 500;
	font-size: 1.4rem;
}
/* 
.checkout-order-item h5{
	font-weight: 400;
	font-size: 1.2rem;
} */

.order-summary{
	padding-left: 20px;
}

.order-confirmation{
	/* min-height: calc(100vh - var(--header-height)); */
    box-sizing: border-box;
}

.order-confirmation-table{
	width:60%;
}

.order-confimration .order-info{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.order-confimration .order-info p{
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 1.8rem;
}

/* make table striped */
.order-confirmation-table tr:nth-child(even){
	background-color: #dad3ce
}

.order-confirmation-table tr:nth-child(odd){
	background-color: #ccc5c0
}

.order-confirmation-table th{
	padding: 10px;
	text-align: left;
}

.order-confirmation-table td{
	padding: 10px;
}

.confirmation-product-image{
	width: 20%;
	text-align: center;
}


.confirmation-product-title{
	width: 40%;
}

.confirmation-product-qty{
	width: 15%;
}

.confirmation-product-price{
	width: 25%;
}

.confirmation-product-image img{
	width: 100%;
	aspect-ratio: 1/1;
	object-fit: cover;
}

.cart-summary.checkout{
	width: 100%;
}

.locations-dropdown{
	border-top: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	max-height: 200px;
	overflow-y: auto;
	background: var(--white);
}

.locations-dropdown .location{
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	border-top: none;
	padding: 20px;
}


.omniva-select input{
	height: 3rem;
	box-sizing: border-box;
	font: inherit;
	padding: 0 10px;
	margin-bottom: 10px;
}

.omniva-select input:focus-visible,
.omniva-select select:focus-visible{
	outline: none;
}

@media screen and (max-width: 700px){
	.checkout-page{
		flex-direction: column;
	}
	.checkout{
		width: 100%;
	}
	.order-summary{
		width: 100%;
		padding-top: 20px;
		padding-left: 0;
	}

	.order-confirmation-table{
		width: 100%;
	}
}
