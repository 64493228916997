/* CART */
.cart {
	display: flex;
	flex-direction: column;
	/* min-height: calc(100vh - var(--header-height)); */
    box-sizing: border-box;
}



.cart-add-coupon{
	display: flex;
	gap: 10px;
}

.cart-add-coupon input{
	box-sizing: border-box;
	padding: 0 10px;
	height: 3rem;
}

.cart-add-coupon input:focus-visible{
	outline: none;
}

.cart-summary{
	width: 20%;
	display: flex;
	flex-direction: column;
}

.cart-summary-info {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.cart-summary p{
	font-size: 1.2rem;
	line-height: 1.8rem;
}
.cart-table{
	 /* Default */
	user-select: none;
	/* Webkit (Safari) */
	-webkit-user-select: none;
	/* Konqueror */
	-khtml-user-select: none;
	/* Mozilla (Firefox) */
	-moz-user-select: none;
	/* IE */
	-ms-user-select: none;
}

.cart-table th{
	text-align: left;
}

.cart-table tr:nth-child(odd) {
    background-color: #e5e5e5;
}

.cart-table tr:nth-child(even) {
    background-color: #d5d5d5;
}

.cart-table td,
.cart-table th{
	box-sizing: border-box;
}

.cart-table td img{
	width: 80px;
	aspect-ratio: 1/1;
	object-fit: cover;
	border-radius: 10px;
}

.cart-table tr{
	height: 100px;
}

.cart-table td,
.cart-table th{
	padding: 0 10px;
}

.cart-table td.image{
	width: 100px;
}

.cart-table td.price{
	width: 150px;
}

.cart-table td.quantity{
	width: 150px;
	box-sizing: border-box;
	padding-right: 10px;
	box-sizing: border-box;
}

.cart-table .quantity-container{
	display: flex;
	justify-content: space-between;
	align-items: center;
	
}

.quantity-controls{
	display: flex;
	flex-direction: column;
	gap: 10px;
	
}
/* .cart-table td.quantity input{
	box-sizing: border-box;
	background: var(--white);
	padding: 0 10px;
	border: none;
	height: 3rem;
} */

.cart-table td.total{
	width: 150px;
}

.cart-table td.remove{
	width: 100px;
	text-align: center;
}

.cart-remove-button{
    color: var(–red);
}

.cart-remove-button:hover{
    cursor: pointer;
    transform: scale(1.1);
}

@media screen and (max-width: 768px){
	.cart-summary{
		width: 100%;
	}

	.cart-table {
		width: 100%;
		font-size: 0.8rem;
	}

	.cart-table tr.table-head{
		height: 50px;
	}

	.cart-table tr{
		height: auto;
	}

	.cart-table td{
		padding: 5px;
	}

	.cart-table td.image{
		width: 20% !important;
	}

	.cart-table td.image img{
		width: 100%;
		aspect-ratio: 1/1;
		object-fit: cover;
		border-radius: 5px;
	}

	.cart-table td{
		box-sizing: border-box;
		overflow: hidden;
	}

	.cart-table td.title{
		width: 20% !important;
	}

	.cart-table td.price{
		width: 20% !important;
	}

	.cart-table td.quantity{
		width: 20% !important;
	}

	.cart-table td.total{
		width: 20% !important;
	}

	.cart-table th.remove{
		display: none;
	}

	.cart-table td.remove{
		display: none;
	}

	

	/* .cart-table td.image{
		width: 20%;
	}

	.cart-table td.image img{
		width: 90%;
	}

	.cart-table td.title{
		width: 20%;
		overflow: hidden;
	}

	.cart-table td.price{
		width: 10%;
		overflow: hidden;
	}

	.cart-table td.quantity{
		width: 10%;
		overflow: hidden;
		box-sizing: border-box;
		background: red;
	}

	.cart-table td.total{
		width: 20%;
		overflow: hidden;
	}

	
	.cart-table td.total{
		width: 10%;
		overflow: hidden;
	}
	
	.cart-table td.remove{
		width: 10%;
		text-align: center;
	} */
	
}